import React from "react"
import { useContactMode } from "../hooks/useContactMode"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, ItemsList } from "./utils"
import logoCompleto from "../images/lte-impianti-logo-completo.svg"
import linkedin from "../images/icons/linkedin.svg"
import { Link } from "gatsby"
import { Phone, Send } from "react-feather"

const Footer = () => {
  const moonLanding = new Date()
  const year = moonLanding.getFullYear()
  const { email, phone, additionalInfo } = useContactMode()
  return (
    <section>
      <Wrapper>
        <Top>
          <div>
            <h6>Azienda</h6>
            <p>
              Leader nella realizzazione e manutenzione di impianti elettrici,
              meccanici e tecnologici grazie all’elevato patrimonio di
              esperienze a livello progettuale, installativo e manutentivo.
            </p>
          </div>
          <div>
            <h6>Soluzioni</h6>
            <ItemsList>
              <li>
                <Link to="/impianti-elettrici-mt-mb-e-speciali">
                  Impianti elettrici MT/BT e speciali
                </Link>
              </li>
              <li>
                <Link to="/impianti-tecnologici">Impianti tecnologici</Link>
              </li>
              <li>
                <Link to="/reti-e-trasmissione-dati">
                  Reti e trasmissione dati
                </Link>
              </li>
              <li>
                <Link to="/energie-rinnovabili">Energie rinnovabili</Link>
              </li>
            </ItemsList>
          </div>
          <div>
            <h6>Assistenza</h6>
            <p>
              Servizio di assistenza 24/24 ore 365/365 giorni con personale
              reperibile per poter far fronte alle criticità anche durante le
              ore notturne e giorni festivi
            </p>
          </div>
          <div>
            <h6>Contatti</h6>
            <ItemsList>
              <Contact>
                <Send size={20} />
                <a
                  href={`mailto:` + email}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {email}
                </a>
              </Contact>
              <Contact>
                <Phone size={20} />
                <a
                  href={`tel:` + phone}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {phone}
                </a>
              </Contact>
            </ItemsList>
            <AdditionalInformation
              dangerouslySetInnerHTML={{ __html: additionalInfo }}
            />
          </div>
        </Top>
      </Wrapper>
      <Bottom>
        <CustomContainer>
          <div>
            <Link to="/">
              <img src={logoCompleto} alt="LTE Impianti Logo" />
            </Link>
            <Copyright>
              <span>© LTE Impianti {year}</span>
              <Link to="/privacy-policy/">Privacy Policy</Link>
              <a href="/politica-integrata-qualita-ambiente-sicurezza">Politica integrata</a>
            </Copyright>
          </div>
          <Logo
            href="https://www.linkedin.com/company/lte-impianti-s.r.l./"
            target="blank"
            rel="noopener nofollow"
          >
            <img src={linkedin} alt="LTE Impianti Linkedin" />
          </Logo>
        </CustomContainer>
      </Bottom>
    </section>
  )
}

const Wrapper = styled.div`
  background: ${DesignTokens.colors.tertiary[500]};
  border-top: 1px solid ${DesignTokens.colors.tertiary[400]};
`

const Top = styled(Container)`
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  @media screen and (min-width: 769px) {
    flex-direction: row;
    & > div {
      width: 25%;
      margin-right: 4%;
    }
  }
  color: ${DesignTokens.colors.tertiary[400]};
  h6 {
    color: ${DesignTokens.colors.white};
    font-size: ${DesignTokens.fontSizes[7]};
    margin-bottom: ${DesignTokens.spacing[7]};
  }
  a {
    color: ${DesignTokens.colors.tertiary[400]};
    &:hover {
      color: ${DesignTokens.colors.white};
    }
  }
  li {
    margin-bottom: ${DesignTokens.spacing[2]};
  }
`

const Bottom = styled.div`
  background: ${DesignTokens.colors.white};
`

const CustomContainer = styled(Container)`
  padding: ${DesignTokens.spacing[3]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div:first-child {
    display: flex;
    flex-direction: row;
  }
`

const Copyright = styled.div`
  padding: 0 ${DesignTokens.spacing[5]};
  display: flex;
  gap: ${DesignTokens.spacing[3]};
  align-items: center;
  color: ${DesignTokens.colors.primary[500]};
  a {
    color: ${DesignTokens.colors.primary[500]};
  }
`

const Logo = styled.a`
  display: flex;
  align-content: center;
  justify-content: center;
`

const Contact = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: ${DesignTokens.spacing[3]};
  }
`

const AdditionalInformation = styled.div`
  p {
    margin-bottom: ${DesignTokens.spacing[2]};
  }
`

export default Footer
