import React from "react"
import styled from 'styled-components'
import DesignTokens from './designTokens'
import { Container } from './utils'
import { Link } from 'gatsby'
const BackLink = ({to, children}) => (
  <StyledContainer>
    <StyledLink to={to}>{children}</StyledLink>
  </StyledContainer>
)

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${DesignTokens.colors.primary[500]};
`

const StyledContainer = styled(Container)`
    padding-top: 3rem;
`

export default BackLink