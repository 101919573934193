import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import { Container } from "../components/utils"
import DesignTokens from "../components/designTokens"
import Footer from "../components/footer"
import BackLink from "../components/backLink"

const Article = ({ data: { article } }) => {
  return (
    <Layout>
      <SEO title={article.title} />
      <Header>
        <h1>{article.title}</h1>
        <h2>News</h2>
      </Header>
      <BackLink to="/news/">⟵ Tutte le news</BackLink>
      <Intro title={article.title} />
      <Details>
        <Published>
          {new Intl.DateTimeFormat("it", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(article.meta.firstPublishedAt))}
        </Published>
        <div dangerouslySetInnerHTML={{ __html: article.text }}></div>
      </Details>
      <Footer />
    </Layout>
  )
}

const Published = styled.p`
  font-size: ${DesignTokens.fontSizes[3]};
  color: ${DesignTokens.colors.secondary[500]};
  font-weight: bold;
  text-transform: uppercase;
`
const Details = styled(Container)`
  max-width: 50rem;
  padding-bottom: 3rem;
  width: 50rem;
  @media screen and (max-width: 769px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
  }
  img {
    width: 100%;
  }
`

export default Article

export const query = graphql`
  query ArticleQuery($slug: String!) {
    article: datoCmsArticle(slug: { eq: $slug }) {
      id
      title
      text
      image {
        url
        alt
      }
      meta {
        firstPublishedAt
      }
    }
  }
`
